<template>
  <div class="main-content">
    <div class="centered-loading-wrapper column" v-if="loading">
      <LoadingSpinner />
      <p>Loading Menus...</p>
    </div>
    <div v-else>
      <div v-if="viewToShow === 'list'" class="menu-list">
        <h3 class="page-heading">Menus</h3>
        <button class="text-icon contained primary" @click="createMenu">
          <Icon :path="mdiPlus" />
          <span class="text">Create</span>
        </button>
        <ag-grid-vue
          class="ag-theme-alpine"
          @grid-ready="onGridReady"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="rowData"
          rowSelection="single"
          @cellClicked="onCellClicked">
        </ag-grid-vue>
      </div>
      <div v-if="viewToShow === 'editor'">
        <div class="editor-wrapper">
          <MenuEditor
            :selectedMenuId="this.selectedMenuId"
            :isNewMenuProp="isNewMenu"
            @menuEdited="setActiveMenu"
            @changeView="changeView" />
        </div>
      </div>
    </div>

    <teleport to="body">
      <transition name="fade">
        <YesNoDialog
          id="unsavedChanges"
          v-if="showModal === 'confirmDelete'"
          :maxWidth="'400'"
          :nameYes="'Confirm'"
          :funcYes="deleteMenu"
          :nameNo="'Cancel'"
          :funcNo="closeModal"
          @close="showModal = ''">
          <h4>Delete Menu?</h4>
          <p style="text-align: center; padding: 1rem">
            <strong>{{ clickedRow.data.name }}</strong>
          </p>
        </YesNoDialog>
      </transition>
    </teleport>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import { AgGridVue } from "ag-grid-vue3";
  import MenuEditor from "@/components/Menus/MenuEditor.vue";
  import LoadingSpinner from "@/components/LoadingSpinner";
  import store from "@/store";
  import { useToast } from "vue-toastification";
  import { cloneButtonText } from "@/helpers/buttonTextDeteminator";
  import YesNoDialog from "@/components/Dialogs/YesNoDialog.vue";
  import { mdiPlus } from "@mdi/js";
  import { OPERATORTYPEOUTLET } from "@/constants/operatorTypes.ts";

  export default {
    components: {
      AgGridVue,
      MenuEditor,
      LoadingSpinner,
      YesNoDialog,
    },
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        viewToShow: "list",
        columnDefs: ["id", "name"],
        defaultColDef: null,
        gridApi: null,
        columnApi: null,
        rowData: [],
        clickedRow: null,
        selectedMenuId: null,
        isNewMenu: false,
        loading: false,
        cloning: false,
        showModal: "",
        mdiPlus,
      };
    },
    computed: {
      ...mapGetters({
        apiPrivateMenusGetByOutletId: "apiPrivateMenus/getByOutletId",
        apiPrivateMenusGetMetaById: "apiPrivateMenus/getMetaById",
        apiPrivateMenusDeleteById: "apiPrivateMenus/deleteById",
      }),
    },
    watch: {
      operatorId: function () {
        this.setActiveMenu();
      },
    },
    methods: {
      // changesMade(item) {
      //   this.selectedItem = item;
      //   this.items[item.id] = item;
      // },
      setActiveMenu() {
        this.loading = true;
        this.unsavedChanges = false;
        this.rowData = [];

        const outletId = this.operatorId;

        this.apiPrivateMenusGetByOutletId(outletId).then((data) => {
          data.forEach((x) => {
            let merged = { ...x.data, ...x.meta };
            this.rowData.push(merged);
          });

          this.loading = false;
        });

        this.defaultColDef = {
          suppressMenu: true,
          suppressMovable: true,
        };

        this.columnDefs = [
          {
            field: "name",
            filter: true,
            suppressSizeToFit: true,
            floatingFilter: true,
          },
          {
            field: "version",
            width: 100,
            suppressSizeToFit: true,
            cellRenderer: (params) => {
              return `v${params.value}`;
            },
          },
          {
            field: "createdOn",
            headerName: "Created",
            suppressSizeToFit: true,
            valueFormatter: this.dateFormatter,
          },
          {
            field: "updatedOn",
            headerName: "Edited",
            suppressSizeToFit: true,
            valueFormatter: this.dateFormatter,
          },
          {
            field: "notes",
            headerName: "Notes",
            width: 180,
            suppressSizeToFit: true,
            cellRenderer: (params) => {
              return params.value || "-";
            },
          },
          {
            field: "clone",
            width: 120,
            cellClass: "action",
            headerName: "",
            suppressSizeToFit: true,
            cellRenderer: () => {
              return `<button type="button" class="text-icon small inline"><span class="icon"><span class="mdi-icon" role="img"><svg fill="#333" class="material-design-icon__svg" width="18" height="18"viewBox="0 0 24 24"><path d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" /></svg></span></span><span class="text">Clone</span></button>`;
            },
          },
          {
            field: "delete",
            width: 100,
            flex: 1,
            cellClass: "action",
            headerName: "",
            suppressSizeToFit: true,
            cellRenderer: () => {
              return '<button @click="deleteItem" type="button" class="text-icon small inline"><span class="icon"><span class="mdi-icon" role="img"><svg fill="#333" class="material-design-icon__svg" width="18" height="18"viewBox="0 0 24 24"><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg></span></span><span class="text">Delete</span></button>';
            },
          },
        ];
      },
      dateFormatter(params) {
        return this.formatDateTime(params.value);
      },
      createMenu() {
        this.isNewMenu = true;
        this.changeView("editor");
      },
      onGridReady(params) {
        // get the grid and column api
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
      },

      onCellClicked(row) {
        this.clickedRow = row;
        if (row.column.colId === "clone") {
          this.cloneMenu(row);
        } else if (row.column.colId === "delete") {
          this.showModal = "confirmDelete";
        } else {
          this.isNewMenu = false;
          this.clickedRow = row;
          this.selectedMenuId = row.data.id;
          this.changeView("editor");
        }
      },
      changeView(viewToChange) {
        if (viewToChange === "list") this.setActiveMenu();
        this.viewToShow = viewToChange;
      },
      cloneMenu(row) {
        this.cloning = true;
        let ele = row.event.srcElement;
        let cloneTextElement = cloneButtonText(ele);
        cloneTextElement.innerText = "Cloning...";

        store.state.apiPrivate.client.endpoints.menus
          .clone(row.data.id)
          .then((response) => {
            if (response.status == 200) {
              const outletId = this.operatorId;
              this.apiPrivateMenusGetByOutletId(outletId).then((data) => {
                this.rowData = [];

                data.forEach((x) => {
                  let merged = { ...x.data, ...x.meta };
                  this.rowData.push(merged);
                });

                this.loading = false;
                this.cloning = false;
                cloneTextElement.innerText = "Clone";
              });
              useToast().success("Menu cloned.");
            } else {
              useToast().error(
                "Failed to create item. Please contact Support."
              );
            }
          });
      },
      deleteMenu() {
        this.showModal = "";
        this.apiPrivateMenusDeleteById(this.clickedRow.data.id).then(
          (response) => {
            if (response.status === "Deleted") {
              useToast().success("Menu deleted.");

              const selectedRow = this.gridApi.getSelectedRows();
              this.gridApi.applyTransaction({ remove: selectedRow });
            } else {
              useToast().error(
                "Failed to delete menu. Please contact Support."
              );
            }
          }
        );
      },
      closeModal() {
        this.showModal = "";
      },
    },
    beforeMount() {
      if (this.operatorType !== OPERATORTYPEOUTLET) {
        this.$router.push({ name: "/dashboard" });
        return;
      }

      this.setActiveMenu();
    },
  };
</script>

<style lang="scss" scoped>
  .menu-list {
    margin: 1rem 0;
  }

  .ag-theme-alpine {
    margin-top: 1rem;
  }
</style>
